import { ColorTheme } from '@types'
import { AnimatePresence, useViewportScroll } from 'framer-motion'
import NextLink from 'next/link'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Box } from 'theme-ui'
import BoxMotion from '../BoxMotion'
import ButtonIcon from '../ButtonIcon'
import Character, { PATTERN_COMPONENTS } from '../Character'
import H3 from '../H3'
import Link from '../Link'

type Props = { colorTheme?: ColorTheme; slug: string; title: string }

const numCharacters = PATTERN_COMPONENTS.length

const LinkedPage = (props: Props) => {
  const { colorTheme, slug, title } = props

  const [visible, setVisible] = useState(true)
  const [inView, setInView] = useState(false)
  const [characterIndex, setCharacterIndex] = useState(-1)

  const { scrollYProgress } = useViewportScroll()

  useEffect(() => {
    const patternStartIndex = Math.floor(Math.random() * numCharacters)
    setCharacterIndex(patternStartIndex)
  }, [])

  useEffect(() => {
    scrollYProgress.onChange(latest => {
      if (!inView && latest > 0.25) {
        setInView(true)
      }
    })
  }, [])

  return (
    <AnimatePresence>
      {visible && inView && (
        <BoxMotion
          // framer-motion
          animate={{ opacity: 1, y: '0px' }}
          initial={{
            opacity: 0,
            y: '20px',
          }}
          exit={{ opacity: 0 }}
          transition={{
            damping: 25,
            stiffness: 150,
            type: 'spring',
          }}
          // theme-ui
          p={[0, null, 4]}
          sx={{
            bottom: 0,
            fontSize: 'xxs',
            position: 'sticky',
            zIndex: 'linkedPage',
          }}
        >
          <Box
            sx={{
              bottom: [0, null, 4],
              position: 'absolute',
              width: ['100%', null, 'auto'],
            }}
          >
            {/* SVG topper */}
            <Box
              color={colorTheme?.widgetBackground || 'midnight'}
              sx={{
                display: ['block', null, 'none'],
                width: '100%',
              }}
            >
              <svg
                fill="none"
                height="23"
                preserveAspectRatio="none"
                style={{ display: 'block' }}
                viewBox="0 0 375 23"
                width="100%"
              >
                <path
                  clipRule="evenodd"
                  d="M0 3.46094C5.15247 3.54883 10.3052 3.64258 15.4579 3.73828C57.0491 4.52344 98.5957 8.96875 140.171 13.416C174.468 17.0859 208.783 20.7578 243.16 22.3789C258.623 23.1797 274.132 18.7188 289.662 14.252C301.919 10.7266 314.188 7.19727 326.458 6.24805C342.592 4.91602 358.789 2.8125 375 0.642578V23H0V3.46094Z"
                  fill="currentColor"
                  fillRule="evenodd"
                />
              </svg>
            </Box>

            {/* Container */}
            <Box
              bg={colorTheme?.widgetBackground || 'midnight'}
              color={colorTheme?.widgetText || 'white'}
              px={6}
              py={5}
              sx={{
                borderRadius: [0, null, 4],
                position: 'relative',
                width: ['100%', null, '250px'],
              }}
            >
              {/* Close button */}
              <Box
                onClick={() => setVisible(false)}
                sx={{
                  position: 'absolute', //
                  right: 4,
                  top: 4,
                  transform: 'translateX(1px)',
                }}
              >
                <ButtonIcon
                  background="white"
                  color="midnight"
                  iconSize="6px"
                  size="18px"
                  type="close"
                />
              </Box>

              <Box
                mb={3}
                sx={{
                  fontSize: 'xs',
                  fontWeight: 'semibold',
                }}
              >
                Why not check out...
              </Box>

              {/* Title */}
              <H3>
                <NextLink href={slug} passHref>
                  <Link variant="simple">{title}</Link>
                </NextLink>
              </H3>

              {/* Arrow */}
              <Box
                bg={colorTheme?.widgetBackground || 'midnight'}
                sx={{
                  display: ['none', null, 'block'],
                  height: '12px',
                  left: 6,
                  position: 'absolute',
                  top: '100%',
                  transform: 'translateY(-50%) rotate(45deg)',
                  width: '12px',
                }}
              />
            </Box>

            {/* Character */}
            <Box
              mt={5}
              sx={{
                display: ['none', null, 'block'],
                height: '33px',
                left: 3,
                position: 'relative',
                // transform: 'translateX(-50%)',
                width: '33px',
              }}
            >
              <Character
                background={colorTheme?.widgetBackground}
                patternIndex={characterIndex}
              />
            </Box>
          </Box>
        </BoxMotion>
      )}
    </AnimatePresence>
  )
}

export default LinkedPage
