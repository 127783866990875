import { Color } from '@types'
import React from 'react'
import theme from '../../styled/theme'
import Path01 from './paths/Path01'
import Path02 from './paths/Path02'
import Path03 from './paths/Path03'
import Path04 from './paths/Path04'
import Path05 from './paths/Path05'
import Path06 from './paths/Path06'
import Path07 from './paths/Path07'
import Path08 from './paths/Path08'
import Path09 from './paths/Path09'
import Path10 from './paths/Path10'
import Path11 from './paths/Path11'
import Path12 from './paths/Path12'
import Path13 from './paths/Path13'
import Path14 from './paths/Path14'
import Path15 from './paths/Path15'

export const PATTERN_COMPONENTS = [
  Path01,
  Path02,
  Path03,
  Path04,
  Path05,
  Path06,
  Path07,
  Path08,
  Path09,
  Path10,
  Path11,
  Path12,
  Path13,
  Path14,
  Path15,
]

export type Props = {
  background?: Color
  color?: Color
  // colorTheme?: ColorTheme
  patternIndex: number
}

const Character = (props: Props) => {
  const {
    background,
    color,
    // colorTheme,
    patternIndex = 0,
  } = props

  const Component = PATTERN_COMPONENTS[patternIndex]

  if (!Component) {
    return null
  }

  return (
    <svg
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      style={{ display: 'block' }}
      viewBox="0 0 150 150"
      width="100%"
    >
      <Component
        background={background && theme.colors[background]}
        color={color && theme.colors[color]}
      />
    </svg>
  )
}

export default Character
