import { PageSettings } from '@types'
import { useEffect } from 'react'
import { navigationActions } from '../redux/modules/navigation'
import { systemActions } from '../redux/modules/system'
import { useAppDispatch } from '../redux/store'

function usePageSettings(settings?: PageSettings) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      systemActions.setNewsletterHidden({
        hidden: !!settings?.newsletter?.hidden,
      })
    )

    dispatch(
      navigationActions.setHeaderCustomColor({
        customColor: settings?.headerColor,
      })
    )

    if (settings?.newsletter?.title) {
      dispatch(
        systemActions.setNewsletterCustomTitle({
          customTitle: settings.newsletter.title,
        })
      )
    }

    return () => {
      dispatch(systemActions.clearNewsletterCustomTitle())
      dispatch(navigationActions.clearHeaderCustomColor())
    }
  }, [
    settings?.headerColor,
    settings?.newsletter?.hidden,
    settings?.newsletter?.title,
  ])
}

export default usePageSettings
