import { Color, Page } from '@types'
import React, { FunctionComponent, ReactNode } from 'react'
import { Box, BoxOwnProps, ThemeUIStyleObject } from 'theme-ui'
import usePageSettings from '../../hooks/usePageSettings'
import LinkedPage from '../LinkedPage'
import PreviewBar from '../PreviewBar'
import SEO from '../SEO'

type Props = {
  children: ReactNode
  headerColor?: Color
  page?: Page
  preview?: boolean
  sx?: ThemeUIStyleObject
}

const PageWrapper: FunctionComponent<BoxOwnProps & Props> = (props: Props) => {
  const { children, headerColor, page, preview, sx, ...rest } = props

  usePageSettings({
    headerColor: page?.hero?.artboard?.colorTheme?.header || headerColor,
    newsletter: page?.footer?.newsletter,
  })

  return (
    <Box sx={sx} {...rest}>
      {children}

      {/* SEO */}
      <SEO
        description={page?.meta?.description}
        image={page?.meta?.image}
        keywords={page?.meta?.keywords}
        title={page?.meta?.title}
      />

      {/* Linked page widget */}
      {page?.linkedPage && (
        <LinkedPage
          colorTheme={page?.hero?.artboard?.colorTheme}
          slug={page.linkedPage.slug}
          title={page.linkedPage.title}
        />
      )}

      {/* Preview */}
      {preview && <PreviewBar />}
    </Box>
  )
}

export default PageWrapper
