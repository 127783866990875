import React from 'react'
import { Box, Flex } from 'theme-ui'
import Button from '../Button'

const PreviewBar = () => {
  return (
    <Flex
      bg="midnight"
      color="white"
      px={[4, null, null, 6]}
      py={3}
      sx={{
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'space-between',
        left: 0,
        position: 'fixed',
        width: '100%',
        zIndex: 'preview',
      }}
    >
      <Box
        sx={{
          fontSize: 'xs',
        }}
      >
        Previewing draft content
      </Box>
      <a href="/api/exit-preview">
        <Button background="white" color="midnight">
          Exit preview
        </Button>
      </a>
    </Flex>
  )
}

export default PreviewBar
